<template>
  <div>
    <v-card>
      <v-card-title>News</v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Title"
                class="dt-text-field"
                outlined
                v-model="form.title"
                :rules="rules.title"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Slug"
                class="dt-text-field"
                outlined
                v-model="form.slug"
                :rules="rules.slug"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-file-input
                label="Image"
                accept="image/*"
                show-size
                outlined
                prepend-icon=""
                @change="uploadImage($event)"
                v-model="form.image"
                :rules="rules.image"
              ></v-file-input>
            </v-col>
            <v-col cols="12">
              <v-textarea
                type="textarea"
                label="Summary"
                outlined
                v-model="form.summary"
                rows="3"
                row-height="30"
                auto-grow
                :rules="rules.summary"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Content URL"
                class="dt-text-field"
                outlined
                v-model="form.content_url"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="mb-6">
              <v-select
                append-icon="mdi-chevron-down"
                :items="dlrList"
                item-text="text"
                item-value="value"
                label="Dealers"
                outlined
                clearable
                multiple
                hide-details
                clear-icon="mdi-close-circle-outline"
                background-color="selects"
                v-model="form.dealers"
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @mousedown.prevent @click="toggle">
                    <v-list-item-action>
                      <v-icon :color="form.dealers.length > 0 ? 'indigo darken-4' : ''">
                        {{ icon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Select All </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:selection="{ item, index }">
                  <span v-if="index <= 1">{{ item.text }},&nbsp;</span>
                  <span v-if="index === 2" class="grey--text text-caption">
                    (+{{ form.dealers.length - 2 }} others)
                  </span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Precedence"
                class="dt-text-field"
                outlined
                type="number"
                v-model="form.precedence"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                type="date"
                label="Published Date"
                class="dt-text-field"
                outlined
                v-model="form.published_at"
                :rules="rules.published_at"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-switch label="Featured" hide-details v-model="form.featured"></v-switch>
            </v-col>
            <v-col cols="12">
              <v-switch label="Status" hide-details v-model="form.status"></v-switch>
            </v-col>
          </v-row>
          <div class="d-flex justify-end mb-6" flat tile>
            <v-btn
              large
              exact
              color="green darken-1"
              class="ma-1 white--text"
              @click="validate(false)"
              :loading="loadingSave"
              :disabled="loadingSave || loadingSaveAndExit"
            >
              <v-icon left>mdi-content-save</v-icon>
              Save
            </v-btn>
            <v-btn
              large
              exact
              color="green lighten-1"
              class="ma-1 white--text"
              @click="validate(true)"
              :loading="loadingSaveAndExit"
              :disabled="loadingSave || loadingSaveAndExit"
            >
              <v-icon left>mdi-content-save</v-icon>
              Save and Exit
            </v-btn>
            <v-btn
              large
              exact
              color="warning"
              class="ma-1 white--text"
              :disabled="loadingSave || loadingSaveAndExit"
              :to="{ name: 'news.index' }"
            >
              <v-icon left>mdi-close</v-icon>
              Cancel
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import apiNewsService from "@/services/api/modules/newsService";

export default {
  components: {},
  props: {
    details: {
      required: false,
      type: Object,
      default() {
        return {
          title: "",
          slug: "",
          summary: "",
          image: null,
          content_url: "",
          precedence: 0,
          featured: null,
          status: null,
          published_at: null,
          dealers: [],
        };
      },
    },
  },
  data() {
    return {
      form: {
        title: "",
        slug: "",
        summary: "",
        image: null,
        content_url: "",
        precedence: 0,
        featured: null,
        status: null,
        published_at: null,
        dealers: [],
      },
      valid: true,
      loadingSave: false,
      loadingSaveAndExit: false,
      rules: {
        title: [(value) => !!value || "Title is required"],
        slug: [(value) => !!value || "Slug is required"],
        image: this.$route.params.id ? [] : [(value) => !!value || "Image is required"],
        published_at: [(value) => !!value || "Published Date is required"],
      },
    };
  },
  computed: {
    ...mapGetters({
      dlrList: "dealer/GET_DEALERS",
    }),
    allDealersSelected() {
      if (!this.form.dealers) {
        return true;
      }

      return this.form.dealers.length === this.dlrList.length;
    },
    allDealersSelectedEdit() {
      if (!this.details.dealers) {
        return true;
      }

      return this.details.dealers.length === this.dlrList.length;
    },
    someDealersSelected() {
      return this.form.dealers.length > 0 && !this.allDealersSelected;
    },
    someDealersSelectedEdit() {
      return this.details.dealers.length > 0 && !this.allDealersSelectedEdit;
    },
    icon() {
      if (this.allDealersSelected) return "mdi-close-box";
      if (this.someDealersSelected) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  watch: {
    "form.title"() {
      if (this.form.title) {
        this.form.slug = this.slugify(this.form.title);
      }
    },
  },
  async mounted() {
    if (null !== this.details.name) {
      this.form.title = this.details.title;
      this.form.slug = this.details.slug;
      this.form.summary = (this.details.summary != null) ? this.details.summary : "";
      this.form.content_url = (this.details.content_url != null) ? this.details.content_url : "";
      this.form.precedence = this.details.precedence;
      this.form.published_at = this.details.published_at;
      this.form.featured = this.details.featured;
      this.form.status = this.details.status;
      this.form.dealers = this.details.dealers ? this.details.dealers : [];
    }
    await this.getDealers();
  },
  methods: {
    ...mapActions({
      save: "news/save",
      getDealerGroups: "dealerGroups/list",
      showSnackbar: "snackbar/show",
      getDealers: "dealer/getDealers",
    }),

    async validate(isExit) {
      const isValid = this.$refs.form.validate();
      if (!isValid) {
        return;
      }

      if (isExit) {
        this.loadingSaveAndExit = true;
      } else {
        this.loadingSave = true;
      }

      await this.saveNews(isExit);
    },

    async saveNews(isExit) {
      const id = this.$route.params.id ? this.$route.params.id : null;
      let fd = new FormData();

      fd.append("title", this.form.title);
      fd.append("slug", this.form.slug);
      fd.append("summary", this.form.summary);
      fd.append("image", this.form.image);
      fd.append("content_url", this.form.content_url);
      fd.append("precedence", this.form.precedence);
      fd.append("featured", this.form.featured == true ? 1 : 0);
      fd.append("status", this.form.status == true ? 1 : 0);
      fd.append("published_at", this.form.published_at);

      let dealers = this.form.dealers;
      if (id) {
        if (this.allDealersSelectedEdit) {
          dealers = [];
        }

        if (this.form.dealers.length === 0) {
          dealers = [];
        }

        fd.append("dealers", JSON.stringify(dealers));
        
        await apiNewsService.update(id, fd).then(() => {
          this.loadingSaveAndExit = false;
          this.loadingSave = false;
        });

        this.showSnackbar({
            text: "News successfully updated.",
          });
      } else {
        if (this.allDealersSelected) {
          dealers = [];
        }

        if (this.form.dealers.length === 0) {
          dealers = [];
        }

        fd.append("dealers", JSON.stringify(dealers));
        
        await apiNewsService.create(fd).then(() => {
          this.loadingSaveAndExit = false;
          this.loadingSave = false;

          this.showSnackbar({
            text: "News successfully created.",
          });
        });
      }

      if (!id) {
        this.$refs.form.reset();
        this.form.status = false;
      }

      if (isExit) {
        this.$router.push({ name: "news.index" });
      }
    },

    uploadImage(image) {
      this.form.image = image;
    },
    toggle() {
      this.$nextTick(() => {
        if (this.allDealersSelected) {
          this.form.dealers = [];
        } else {
          this.form.dealers = this.dlrList.slice();
        }
      });
    },
    toggleEdit() {
      this.$nextTick(() => {
        if (this.allDealersSelectedEdit) {
          this.details.dealers = [];
        } else {
          this.details.dealers = this.dlrList.slice();
        }
      });
    },
  },
};
</script>

<style></style>
